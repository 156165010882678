import { ShopifyData } from "../query";

export const updateCart = async (cartId, product) => {
  try {
    const query = `mutation {
        cartLinesUpdate(cartId: "${cartId}", lines: [{id: "${product.lineId}", quantity: ${product.quantity}, merchandiseId: "${product.shopifyId}"}]) {
          cart {
            id
            checkoutUrl
            buyerIdentity {
              email
              countryCode
              customer {
                displayName
              }
            }
            lines(first: 25) {
              nodes {
                id
                sellingPlanAllocation {
                  sellingPlan {
                    id
                    name
                  }
                  priceAdjustments { 
                    price {
                      amount
                    }
                  }
                }
                merchandise {
                  ... on ProductVariant {
                    id
                    title
                    image {
                      url
                    }
                    price {
                      amount
                    }
                  }
                }
                quantity
              }
            }
            attributes {
              key
              value
            }
            cost {
              totalAmount {
                amount
                currencyCode
              }
              subtotalAmount {
                amount
                currencyCode
              }
              totalTaxAmount {
                amount
                currencyCode
              }
              totalDutyAmount {
                amount
                currencyCode
              }
            }
          }
          userErrors {
            code
            field
            message
          }
        }
      }`;
    const response = await ShopifyData(query);
    return response.data.cartLinesUpdate.cart;
  } catch (err) {
    console.log(err);
  }
};
