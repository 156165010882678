import { ShopifyData } from "../query";

export const getCart = async (id) => {
  try {
    const query = `{
        cart(id: "${id}") {
          id
          checkoutUrl
          lines(first: 25) {
            nodes {
              id
              sellingPlanAllocation {
                sellingPlan {
                  id
                  name
                }
                priceAdjustments {
                  price {
                    amount
                  }
                }
              }
              merchandise {
                ... on ProductVariant {
                  id
                  product {
                    id
                    title
                    handle
                  }
                  availableForSale
                  title
                  image {
                    url
                  }
                  price {
                    amount
                  }
                }
              }
              quantity
            }
          }
          attributes {
            key
            value
          }
          cost {
            totalAmount {
              amount
              currencyCode
            }
            subtotalAmount {
              amount
              currencyCode
            }
            totalTaxAmount {
              amount
              currencyCode
            }
            totalDutyAmount {
              amount
              currencyCode
            }
          }
        }
     }`;

    const response = await ShopifyData(query);

    return response?.data.cart;
  } catch (error) {
    console.log(error);
  }
};
