import { ShopifyData } from "../query";

export const createCart = async (product) => {
  try {
    const query = `mutation {
      cartCreate(input: {lines: [{merchandiseId: "${product.shopifyId}", quantity: ${product.quantity}}]}) {
        cart {
          id
          checkoutUrl
          lines(first: 25) {
            nodes {
              id
              sellingPlanAllocation {
                sellingPlan {
                  id
                  name
                }
                priceAdjustments {
                  price {
                    amount
                  }
                }
              }
              merchandise {
                ... on ProductVariant {
                  id
                  title
                  image {
                    url
                  }
                  price {
                    amount
                  }
                }
              }
              quantity
            }
          }
          attributes {
            key
            value
          }
          cost {
            totalAmount {
              amount
              currencyCode
            }
            subtotalAmount {
              amount
              currencyCode
            }
            totalTaxAmount {
              amount
              currencyCode
            }
            totalDutyAmount {
              amount
              currencyCode
            }
          }
        }
        userErrors {
          field
          code
          message
        }
      }
    }    
    `;

    const response = await ShopifyData(query);
    return response.data.cartCreate.cart;
  } catch (error) {
    console.log(error);
  }
};
