import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React, { useState } from "react";
import CustomLink from "./link";

const Sidebar = ({
  showSidebar,
  setShowSidebar,
  menu,
  logo,
  socialLinks = [],
}) => {
  const [activeLink, setActiveLink] = useState("");

  const handleMenuDropDown = (page) => {
    setActiveLink(page);
  };

  return (
    <>
      <div className="fix">
        <div className={`side-info ${showSidebar ? "info-open" : ""}`}>
          <div className="side-info-content">
            <div className="offset-widget offset-logo mb-40">
              <div className="row align-items-center">
                <div className="col-9">
                  <Link className="no-underline" to="/">
                    <GatsbyImage
                      image={logo.image.asset.gatsbyImageData}
                      alt={logo.alt}
                      loading="eager"
                      objectFit="contain"
                      className="w-full h-full"
                    />
                  </Link>
                </div>
                <div className="col-3 text-end">
                  <button
                    className="side-info-close"
                    aria-label="button"
                    onClick={() => setShowSidebar(false)}
                  >
                    <i className="fal fa-times"></i>
                  </button>
                </div>
              </div>
            </div>
            {/* <!-- side-mobile-menu start --> */}
            <nav className="side-mobile-menu d-block d-xl-none mm-menu">
              <ul>
                {menu?.map((menuItem, index) => {
                  if (menuItem.itemType === "category") {
                    return (
                      <li
                        key={index}
                        className={`menu-item-has-children has-droupdown ${
                          activeLink === menuItem?.category?.slug?.current
                            ? "active"
                            : ""
                        }`}
                      >
                        <div className="flex items-center justify-between">
                          <Link
                            to={`/${menuItem.category.slug.current}`}
                            className="no-underline"
                            // onClick={() =>
                            //   handleMenuDropDown(
                            //     activeLink !== menuItem?.category?.slug?.current
                            //       ? menuItem?.category?.slug?.current
                            //       : ""
                            //   )
                            // }
                            onClick={() => setShowSidebar(false)}
                          >
                            {menuItem?.category?.name}
                          </Link>
                          <div
                            className="accordion_icon relative"
                            title="Dropdown"
                            onClick={() =>
                              handleMenuDropDown(
                                activeLink !== menuItem?.category?.slug?.current
                                  ? menuItem?.category?.slug?.current
                                  : ""
                              )
                            }
                          />
                        </div>
                        {menuItem?.tags?.length > 0 && (
                          <ul
                            onClick={() => handleMenuDropDown("")}
                            className={`sub-menu ${
                              activeLink === menuItem?.category?.slug?.current
                                ? "active"
                                : ""
                            }`}
                          >
                            {menuItem?.tags?.map((tag, subIndex) => (
                              <li key={subIndex}>
                                <Link
                                  onClick={() => setShowSidebar(false)}
                                  to={`/${tag.category.slug.current}/tag/${tag.slug.current}`}
                                  className="no-underline"
                                >
                                  {tag.name}
                                </Link>
                              </li>
                            ))}
                          </ul>
                        )}
                      </li>
                    );
                  } else if (menuItem.itemType === "none") {
                    return (
                      <li
                        key={index}
                        className={`menu-item-has-children has-droupdown ${
                          activeLink === menuItem.menuTitle ? "active" : ""
                        }`}
                      >
                        <a
                          onClick={() =>
                            handleMenuDropDown(
                              activeLink !== menuItem.menuTitle
                                ? menuItem.menuTitle
                                : ""
                            )
                          }
                          role="button"
                        >
                          {menuItem.menuTitle}
                        </a>
                        {menuItem.links.length > 0 && (
                          <ul
                            onClick={() => handleMenuDropDown("")}
                            className={`sub-menu ${
                              activeLink === menuItem.menuTitle ? "active" : ""
                            }`}
                          >
                            {menuItem.links.map((subMenuItem, subIndex) => (
                              <li key={subIndex}>
                                <CustomLink
                                  data={subMenuItem}
                                  classNames="no-underline"
                                />
                              </li>
                            ))}
                          </ul>
                        )}
                      </li>
                    );
                  } else if (menuItem.itemType === "link") {
                    return (
                      <li
                        key={index}
                        className={`${
                          menuItem.links.length > 0 ? "active" : "mega-menu"
                        }`}
                        onClick={() => setShowSidebar(false)}
                      >
                        <Link
                          to={
                            menuItem.menuLink.current.startsWith("/")
                              ? menuItem.menuLink.current
                              : `/${menuItem.menuLink.current}`
                          }
                          className="text-inherit no-underline font-medium cursor-pointer"
                        >
                          {menuItem.menuTitle}
                        </Link>
                      </li>
                    );
                  } else {
                    return (
                      <li>
                        <CustomLink data={menuItem} classNames="no-underline" />
                      </li>
                    );
                  }
                })}
              </ul>
            </nav>
            {socialLinks.length > 0 && (
              <div className="offset-widget offset-social mb-30">
                <div className="footer-social">
                  {/* <span>Connect:</span> */}
                  <div className="social-links">
                    <ul>
                      {socialLinks.map((socialLink, index) => (
                        <li key={index}>
                          <a
                            href={socialLink.url}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <GatsbyImage
                              image={socialLink.icon.asset.gatsbyImageData}
                              title={socialLink.text}
                              alt={socialLink.text}
                              objectFit="contain"
                              className="w-8 h-8 object-contain"
                              loading="lazy"
                            />
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div
        onClick={() => setShowSidebar(false)}
        className={`offcanvas-overlay ${showSidebar ? "overlay-open" : ""}`}
      ></div>
    </>
  );
};

export default Sidebar;
