import { ShopifyData } from "../query";

export const addItem = async (cartId, product) => {
  try {
    const query = `mutation {
        cartLinesAdd(cartId: "${cartId}", lines: [{ merchandiseId: "${product.merchandiseId}", quantity: ${product.quantity} }]) {
          cart {
            id
            checkoutUrl
            lines(first: 25) {
              nodes {
                id
                sellingPlanAllocation {
                  sellingPlan {
                    id
                    name
                  }
                  priceAdjustments { 
                    price {
                      amount
                    }
                  }
                }
                merchandise {
                  ... on ProductVariant {
                    id
                    title
                    image {
                      url
                    }
                    price {
                      amount
                    }
                  }
                }
                quantity
              }
            }
            attributes {
              key
              value
            }
            cost {
              totalAmount {
                amount
                currencyCode
              }
              subtotalAmount {
                amount
                currencyCode
              }
              totalTaxAmount {
                amount
                currencyCode
              }
              totalDutyAmount {
                amount
                currencyCode
              }
            }
          }
          userErrors {
            code
            field
            message
          }
        }
      }
      `;

    const response = await ShopifyData(query);
    return response.data.cartLinesAdd.cart;
  } catch (error) {
    console.log(error);
  }
};
