import { Link } from "gatsby";
import React from "react";
import CustomLink from "../../common/link";

const NavMenu = ({ menu, openPopup, setPdf }) => {
  return (
    <ul className="mb-0 pl-0">
      {menu?.map((menuItem, index) => {
        if (menuItem.itemType === "category") {
          return (
            <li
              key={index}
              className={`${
                menuItem?.tags?.length > 0
                  ? "active menu-item-has-children"
                  : "mega-menu"
              }`}
            >
              <Link
                to={`/${menuItem.category.slug.current}`}
                className="text-inherit no-underline font-medium"
              >
                {menuItem.category.name}
              </Link>

              {menuItem?.tags?.length > 0 && (
                <ul className="sub-menu">
                  {menuItem?.tags?.map((tag, subIndex) => (
                    <li key={subIndex}>
                      <Link
                        to={`/${tag.category.slug.current}/tag/${tag.slug.current}`}
                        className="text-inherit no-underline font-medium"
                      >
                        {tag.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              )}
            </li>
          );
        } else if (menuItem.itemType === "none") {
          return (
            <li
              key={index}
              className={`${
                menuItem.links.length > 0
                  ? "active menu-item-has-children"
                  : "mega-menu"
              }`}
            >
              <span className="text-inherit no-underline font-medium cursor-pointer">
                {menuItem.menuTitle}
              </span>
              {menuItem?.links.length > 0 && (
                <ul className="sub-menu">
                  {menuItem?.links?.map((link, subIndex) => (
                    <li key={subIndex}>
                      <CustomLink
                        data={link}
                        classNames="text-inherit no-underline font-medium"
                        openPopup={() => openPopup(true)}
                        setPdf={setPdf}
                      />
                    </li>
                  ))}
                </ul>
              )}
            </li>
          );
        } else if (menuItem.itemType === "link") {
          return (
            <li
              key={index}
              className={`${
                menuItem.links.length > 0 ? "active" : "mega-menu"
              }`}
            >
              <Link
                to={
                  menuItem.menuLink.current.startsWith("/")
                    ? menuItem.menuLink.current
                    : `/${menuItem.menuLink.current}`
                }
                className="text-inherit no-underline font-medium cursor-pointer"
              >
                {menuItem.menuTitle}
              </Link>
            </li>
          );
        }
      })}
    </ul>
  );
};

export default NavMenu;
