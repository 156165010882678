import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import { A11y, Autoplay, Navigation, Scrollbar } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

const HeaderAdSpace = ({ data = [], barEnabled, bottomPadding = false }) => {
  const onAdClick = (link, title) => {
    if (typeof window !== "undefined" && window.gtag) {
      window?.gtag("event", "click_on_ad", {
        ad_link: link,
        ad_title: title,
      });
    }
  };

  return (
    <div
      className={`w-full h-28 ${bottomPadding && "mb-[20px]"} ${
        !barEnabled ? "mt-[20px]" : "mt-[60px]"
      } my-ad`}
    >
      <Swiper
        modules={[Autoplay, Navigation, Scrollbar, A11y]}
        spaceBetween={30}
        slidesPerView={1}
        loop={true}
        speed={500}
        autoplay={{
          delay: 4000,
          disableOnInteraction: false,
        }}
        className="max-w-4xl mx-auto w-full"
        navigation={{
          clickable: true,
          nextEl: ".portfolio-button-next",
          prevEl: ".portfolio-button-prev",
        }}
      >
        {data.map((item, index) => (
          <SwiperSlide key={index}>
            <div
              key={index}
              className="max-w-4xl mx-auto w-full sm:px-10 xl:px-0"
            >
              <a
                href={item.link}
                title={item.adName}
                target="_blank"
                rel="noreferrer"
                onClick={() => onAdClick(item.link, item.adName)}
              >
                <GatsbyImage
                  image={item.adImage.asset.gatsbyImageData}
                  alt={item.adName}
                  loading="lazy"
                  objectFit="cover"
                  objectPosition="left"
                  className="w-full object-cover object-left hidden sm:block"
                  imgClassName="object-left"
                />
                <GatsbyImage
                  image={item.adImageMobile.asset.gatsbyImageData}
                  alt={item.adName}
                  loading="lazy"
                  objectFit="cover"
                  objectPosition="left"
                  className="w-full object-cover object-left block sm:hidden"
                  imgClassName="object-left"
                />
              </a>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default HeaderAdSpace;
