import { PortableText } from "@portabletext/react";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React, { Fragment, useEffect, useState } from "react";
import { Cookies } from "react-cookie-consent";
import NewletterModal from "../common/NewletterModal";
import AnnouncementBar from "../common/annoucementbar";
import Popup from "../common/popup";
import CookiesBanner from "./CookiesBanner";
import Footer from "./footer";
import Header from "./header";

const Layout = ({ children }) => {
  const { sanitySitesettings } = useStaticQuery(graphql`
    {
      sanitySitesettings {
        cursor {
          enable
          color {
            hex
          }
        }
        announcementbar {
          enable
          bgColor {
            hex
          }
          textColor {
            hex
          }
          announcements {
            announcementText
            linkType
            link
          }
        }
        leaderboardAds {
          code
        }
        headerAdSpace {
          adName
          adImage {
            asset {
              gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: BLURRED
                formats: WEBP
              )
            }
          }
          adImageMobile {
            asset {
              gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: BLURRED
                formats: WEBP
              )
            }
          }
          link
        }
        header {
          logo {
            alt
            image {
              asset {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  placeholder: BLURRED
                  formats: WEBP
                )
              }
            }
          }
          menuLinks {
            itemType
            category {
              name
              slug {
                current
              }
            }
            menuLink {
              current
            }
            tags {
              name
              slug {
                current
              }
              category {
                name
                slug {
                  current
                }
              }
            }
            menuTitle
            links {
              linkTitle
              linkType
              slug: linkSlug {
                current
              }
              url
              sectionId
              file {
                asset {
                  url
                }
              }
            }
          }
          ctaButtonText
        }
        footer {
          footerLogo {
            alt
            image {
              asset {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  placeholder: BLURRED
                  formats: WEBP
                )
              }
            }
          }
          about {
            title
            description
          }
          pagesLinks {
            title
            pagesLinks {
              linkTitle
              linkType
              slug: linkSlug {
                current
              }
              url
              sectionId
              file {
                asset {
                  url
                }
              }
            }
          }
          getInTouch {
            title
            email {
              icon {
                asset {
                  gatsbyImageData(
                    layout: FULL_WIDTH
                    placeholder: BLURRED
                    formats: WEBP
                  )
                }
              }
              text
              url
            }
            phone {
              icon {
                asset {
                  gatsbyImageData(
                    layout: FULL_WIDTH
                    placeholder: BLURRED
                    formats: WEBP
                  )
                }
              }
              text
              url
            }
            address {
              icon {
                asset {
                  gatsbyImageData(
                    layout: FULL_WIDTH
                    placeholder: BLURRED
                    formats: WEBP
                  )
                }
              }
              text
            }
          }
          subfooter {
            publicationLogo {
              alt
              image {
                asset {
                  gatsbyImageData(
                    layout: FULL_WIDTH
                    placeholder: BLURRED
                    formats: WEBP
                  )
                }
              }
            }
            _rawText
            bgColor {
              hex
            }
            textColor {
              hex
            }
          }
        }
        enablePopup
        popup {
          popupDelay
          title
          description
          image {
            alt
            image {
              asset {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  placeholder: BLURRED
                  formats: WEBP
                )
              }
            }
          }
          buttonText
          linkType
          slug {
            current
          }
          url
        }
        socialLinks {
          icon {
            asset {
              gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: BLURRED
                formats: WEBP
              )
            }
          }
          text
          url
        }
        _rawCc
      }
    }
  `);

  const [showPopup, setShowPopup] = useState(false);
  const [subscribePopup, setSubscribePopup] = useState(false);

  useEffect(() => {
    const cookie = Cookies.get("acreagelife-gdpr-cookies");

    setTimeout(() => {
      console.log("Running");
      if (cookie) {
        setShowPopup(true);
      } else {
        setShowPopup(false);
      }
    }, sanitySitesettings.popup.popupDelay);
  }, []);

  return (
    <Fragment>
      {sanitySitesettings.announcementbar.enable && (
        <AnnouncementBar annoucementbar={sanitySitesettings.announcementbar} />
      )}

      <Header
        headerAds={sanitySitesettings.headerAdSpace}
        reviveAds={sanitySitesettings.leaderboardAds}
        data={sanitySitesettings.header}
        socialLinks={sanitySitesettings.socialLinks}
        barEnabled={sanitySitesettings.announcementbar.enable}
      />
      {children}
      <Footer
        footer={sanitySitesettings.footer}
        cc={sanitySitesettings._rawCc}
        socialLinks={sanitySitesettings.socialLinks}
      />

      {sanitySitesettings.footer.subfooter && (
        <div
          className="border-t w-full h-full border-t-gray-200 border-solid py-3"
          style={{
            backgroundColor: sanitySitesettings.footer.subfooter?.bgColor?.hex,
          }}
        >
          <div className="container  text-black">
            <div className="flex w-full h-full min-h-[40px] items-center space-x-2 text-xs md:text-base">
              <div className="w-[100px] h-[40px]">
                <GatsbyImage
                  image={
                    sanitySitesettings.footer.subfooter.publicationLogo.image
                      .asset.gatsbyImageData
                  }
                  alt={sanitySitesettings.footer.subfooter.publicationLogo.alt}
                  width={100}
                  height={100}
                  className="object-contain"
                  loading="lazy"
                />
              </div>
              <PortableText
                value={sanitySitesettings.footer.subfooter._rawText}
                components={{
                  block: {
                    normal: ({ children, value }) => {
                      return (
                        <p
                          style={{
                            color:
                              sanitySitesettings.footer.subfooter?.textColor
                                ?.hex,
                          }}
                          className="mb-0 text-sm md:text-base text-black"
                        >
                          {children}
                        </p>
                      );
                    },
                  },
                  marks: {
                    link: ({ children, value }) => {
                      const rel = !value.href.startsWith("/")
                        ? "noreferrer noopener"
                        : undefined;

                      return (
                        <a
                          className="text-inherit hover:underline underline-offset-[5px] hover:text-green"
                          href={value.href}
                          rel={rel}
                          target="_blank"
                        >
                          {children}
                        </a>
                      );
                    },
                  },
                }}
              />
            </div>
          </div>
        </div>
      )}
      {sanitySitesettings.enablePopup && (
        <Popup
          onClose={() => setShowPopup(false)}
          value={showPopup}
          logo={sanitySitesettings.header.logo}
          data={sanitySitesettings.popup}
          setPopup={setSubscribePopup}
        />
      )}

      {subscribePopup && (
        <NewletterModal
          closeModal={() => {
            setSubscribePopup(false);
            setShowPopup(false);
          }}
        />
      )}
      <CookiesBanner />
    </Fragment>
  );
};

export default Layout;
