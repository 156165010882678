import { Link } from "gatsby";
import React from "react";

export function LinkContainer({ children, linkType, link, cls }) {
  if (linkType === "none") {
    return <div className={cls}>{children}</div>;
  } else if (linkType === "internal") {
    return (
      <Link to={link} className={cls}>
        {children}
      </Link>
    );
  } else if (linkType === "external") {
    return (
      <a href={link} className={cls}>
        {children}
      </a>
    );
  }
}
