import React, { Fragment } from "react";
import { Link } from "gatsby";
import { Link as LinkScroll } from "react-scroll";

const CustomLink = ({ data, classNames, openPopup, setPdf }) => {
  function handleClick(e, link) {
    if (link && setPdf && openPopup) {
      setPdf(link);
      openPopup(e);
    }
  }
  return (
    <Fragment>
      {data.linkType === "slug" && data?.slug?.current && (
        <Link className={classNames} to={`/${data.slug.current}`}>
          {data.linkTitle}
        </Link>
      )}
      {data.linkType === "url" && data?.url && (
        <a
          className={classNames}
          href={data.url}
          target="_blank"
          rel="noreferrer"
        >
          {data.linkTitle}
        </a>
      )}
      {data.linkType === "popup" && data?.file?.asset?.url && (
        <a
          className={`${classNames} cursor-pointer`}
          onClick={(e) => handleClick(e, data.file.asset.url)}
        >
          {data.linkTitle}
        </a>
      )}
      {data.linkType === "sectionId" && data?.sectionId && (
        <LinkScroll
          className={classNames}
          to={data.sectionId}
          smooth
          duration={300}
        >
          {data.linkTitle}
        </LinkScroll>
      )}
    </Fragment>
  );
};
export const CustomLinkWithChild = ({
  data,
  classNames,
  children,
  openPopup,
  setPdf,
}) => {
  function handleClick(e, link) {
    if (link && setPdf && openPopup) {
      setPdf(link);
      openPopup(e);
    }
  }
  return (
    <Fragment>
      {data.linkType === "slug" && data?.slug?.current && (
        <Link className={classNames} to={data.slug.current}>
          {children}
        </Link>
      )}
      {data.linkType === "url" && data?.url && (
        <a
          className={classNames}
          href={data.url}
          target="_blank"
          rel="noreferrer"
        >
          {children}
        </a>
      )}
      {data.linkType === "popup" && data?.file?.asset?.url && (
        <a
          className={`${classNames} cursor-pointer`}
          onClick={(e) => handleClick(e, data.file.asset.url)}
        >
          {data.linkTitle}
        </a>
      )}
      {data.linkType === "sectionId" && data?.sectionId && (
        <LinkScroll
          className={classNames}
          to={data.sectionId}
          smooth
          duration={300}
        >
          {children}
        </LinkScroll>
      )}
    </Fragment>
  );
};

export default CustomLink;
