import React, { useRef, useState, useEffect } from "react";

const NewletterModal = ({ closeModal }) => {
  let [name, setName] = useState("");
  let [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");

  var portalId = "4138839";
  var formId = "f0ecb67f-94bc-4fd5-814e-c934e3acff22";
  const postUrl = `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formId}`;
  const isBrowser = typeof window !== "undefined";
  const pageUri = isBrowser ? window.location.href : null;
  const pageName = isBrowser ? document.title : null;

  function handleSubmit(e) {
    e.preventDefault();

    const body = {
      submittedAt: Date.now(),
      fields: [
        {
          name: "full_name",
          value: name,
        },
        {
          name: "email",
          value: email,
        },
      ],
      context: {
        pageUri,
        pageName,
      },
    };

    fetch(postUrl, {
      method: "post",
      body: JSON.stringify(body),
      headers: new Headers({
        "Content-Type": "application/json",
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        setSuccess(data?.inlineMessage);
        setError("");
        setEmail("");
        setName("");
        setLoading(false);
      })
      .catch((err) => {
        // console.log(err);
        setSuccess("");
        setEmail("");
        setName("");
        setError(
          "There is some error in submitting the form. Please try later"
        );
        setLoading(false);
      });
  }

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (success || error) {
        setSuccess("");
        setError("");
        closeModal();
      }
    }, 3000);

    return () => clearTimeout(timeout);
  }, [success, error]);

  return (
    <div
      className="fixed top-0 left-0 w-full h-full bg-black/20 z-[999]"
      onClick={closeModal}
    >
      <div className="relative w-full h-full flex items-center justify-center">
        <div
          className="relative max-w-xl w-full h-auto py-10 px-5 bg-white"
          onClick={(e) => e.stopPropagation()}
        >
          <button
            className="absolute top-1 right-1 text-black outline-none p-1"
            onClick={closeModal}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              className="w-6 h-6"
            >
              <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z" />
            </svg>
          </button>
          <form onSubmit={handleSubmit} className="space-y-2">
            <h4 className="font-bold">
              Thank you for joining the AcreageLife community.
            </h4>
            <p>
              Check your email after signing up and start enjoying all
              AcreageLife has to offer.
            </p>

            <input
              type="name"
              name="name"
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
              className="w-full h-10 border border-solid border-black focus:outline-none outline-none px-2 py-1 mb-2"
              placeholder="Enter your name"
            />
            <input
              type="email"
              name="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className="w-full h-10 border border-solid border-black focus:outline-none outline-none px-2 py-1 mb-2"
              placeholder="Enter your email"
            />
            <button
              type="submit"
              disabled={loading}
              className="bg-[#0b3d2c] w-40 h-10 text-white"
            >
              Submit
            </button>
          </form>
          {success && (
            <div className="text-white px-6 py-2 border-0 relative mb-4 bg-[#0B3D2C] mt-4">
              <span className="text-xl inline-block mr-5 align-middle">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z"
                  />
                </svg>
              </span>
              <span className="inline-block align-middle mr-8">{success}</span>
              <button
                onClick={() => setSuccess("")}
                className="absolute bg-transparent text-2xl font-semibold leading-none right-0 top-0 mt-2 mr-6 outline-none focus:outline-none"
              >
                <span>×</span>
              </button>
            </div>
          )}
          {error && (
            <div className="text-white px-6 py-2 border-0 relative mb-4 bg-red-600 mt-4">
              <span className="text-xl inline-block mr-5 align-middle">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z"
                  />
                </svg>
              </span>
              <span className="inline-block align-middle mr-8">{error}</span>
              <button
                onClick={() => setError("")}
                className="absolute bg-transparent text-2xl font-semibold leading-none right-0 top-0 mt-2 mr-6 outline-none focus:outline-none"
              >
                <span>×</span>
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default NewletterModal;
