import React from "react";
import "bootstrap/dist/js/bootstrap";
import "./src/styles/tailwind.css";
import "./src/styles/global.scss";
import Layout from "./src/components/layout";
import ShopProvider from "./src/lib/context/cartContext";
import { ApolloProvider } from "@apollo/client";
import client from "./src/apollo/client";
import "swiper/css/bundle";

export const wrapPageElement = ({ element }) => {
  return (
    <ApolloProvider client={client}>
      <ShopProvider>
        <Layout>{element}</Layout>
      </ShopProvider>
    </ApolloProvider>
  );
};

export const shouldUpdateScroll = ({ routerProps: { location } }) => {
  if (location.hash === "") {
    window.scrollTo(0, 0);
  }

  return false;
};
